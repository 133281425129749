import React from 'react'
import { NavLink } from 'react-router-dom'

function Footer() {
  return (
    <div>
         <footer id="footer">



<div class="footer-top">
  <div class="container">
    <div class="row">

      <div class="col-lg-3 col-md-6 footer-contact">
     
        <h3 style={{fontSize:"20px",color:'white'}}>
        <span style={{color:'#e7393f'}}> सांगली शिक्षण संस्था</span> </h3>
        <p style={{color:'white'}}>
        द्वारा - सिटी हायस्कूल <br/>
        गांवभाग, सांगली  <br/>
        Maharashtra ४१६४१६ <br/>
        
          <strong style={{color:'white'}}>Phone:</strong> (०२३३) २३३२२९९, २३३२९९७, २३३०३७९, २३३९५०३, २३३९७९२<br/>
          <strong style={{color:'white'}}>Email:</strong> sangli.shikshan@gmail.com<br/>
        </p>
      </div>

      <div class="col-lg-3 col-md-6 footer-links">
        <h4 style={{color:'white'}}>Links</h4>
        <ul>
          {/* <li ><i class="bx bx-chevron-right"></i> <a href="#" style={{color:'white'}}>Youtube</a></li>
        
          <li><i class="bx bx-chevron-right"></i> <a href="#" style={{color:'white'}}>Instagram</a></li> */}
          <li><i class="bx bx-chevron-right"></i> 
          <NavLink 
    to='/terms-conditions'
    style={{color:'white'}}
    >
          Terms and conditions
          </NavLink>
          </li>
          <li><i class="bx bx-chevron-right"></i> 
          <NavLink 
    to='/privacy-policy'
    style={{color:'white'}}
    >
          Privacy policy
          </NavLink>
          </li>
          <li>
          <i class="bx bx-chevron-right"></i> 
          <NavLink 
    to='/refund-policy'
    style={{color:'white'}}
    >
          Refund policy
          </NavLink>
          </li>
        
         
        </ul>
      </div>

      {/* <div class="col-lg-3 col-md-6 footer-links">
        <h4 style={{color:'white'}}>Useful Links</h4>
        <ul style={{color:'white'}}>
         
          <li><i class="bx bx-chevron-right"></i> <a href="#" style={{color:'white'}}>Books</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#" style={{color:'white'}}>Podcasts</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#" style={{color:'white'}}>About</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#" style={{color:'white'}}>Admission</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#" style={{color:'white'}}>Online Courses</a></li>

        </ul>
      </div> */}

      <div class="col-lg-3 col-md-6 footer-links">
        <h4 style={{color:'white'}}>Our Social Networks</h4>
        {/* <p style={{color:'white'}}>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p> */}
        <div class="social-links mt-3">
        
          <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
          <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
          {/* <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
          <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a> */}
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container footer-bottom clearfix" style={{backgroundColor:'#031323'}}>
  <div class="copyright">
    &copy; Copyright <strong><span style={{color:'#e7393f'}}>सांगली शिक्षण संस्था</span></strong>. 
    All Rights Reserved
  </div>
  {/* <div class="credits">
   
    Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
  </div> */}
</div>
</footer>
<a href="#" class="back-to-top d-flex align-items-center justify-content-center">
  <i class="bi bi-arrow-up-short"></i></a>

    </div>
  )
}

export default Footer