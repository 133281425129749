// import logo from './logo.svg';
// import './App.css';
import React,{useEffect} from 'react'

import AppRoutes from './AppRoutes';

import './assets/vendor/bootstrap/css/bootstrap.min.css'
import './assets/vendor/bootstrap-icons/bootstrap-icons.css'
import './assets/vendor/boxicons/css/boxicons.min.css'
import './assets/vendor/glightbox/css/glightbox.min.css'
import './assets/vendor/remixicon/remixicon.css'
import './assets/vendor/swiper/swiper-bundle.min.css'
import './assets/css/style.css'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from './Store';
//import './assets/vendor/bootstrap/js/bootstrap.bundle.min.js'

function App() {
 
  return (
    <>
<Provider store={store}>
 
<AppRoutes/>
</Provider>
   
    </>
  );
}

export default App;
