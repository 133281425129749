import { api } from '../../api'
import getPersonalResult from '../school/getPersonalResult'
import getAllCenters from './getAllCenters'
import getResult from './getResult'


export const resultsApi = api.injectEndpoints({
    endpoints: build => ({
        GetAllCenters: getAllCenters(build),         
        GetResult:getResult(build),     
    }),
    overrideExisting: true,   
})
export const {useGetAllCentersQuery,useGetResultMutation} = resultsApi
