import React,{useEffect,useRef,useState} from 'react'
import WebTemplate from '../Components/WebTemplate'
import { useGetAllSchoolsQuery} from '../Services/modules/school';
import { Link,useSearchParams  } from 'react-router-dom';

function Schools() {
 
  const [filter1, setFilter1] = useState('');
  const [filter2, setFilter2] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { 
    data: schools= [],  
    isLoading:loading=true,
    isSuccess:getSchoolSuccess=false
   } = useGetAllSchoolsQuery();
   useEffect(() => {    
     
    if (getSchoolSuccess) {
       
       
          setFilteredData(schools.data);
        
    }
   
}, [schools]);
    const SchoolsScreen=()=>{  
      const handleFilterChange = (event) => {
        const { name, value } = event.target;
    
        if (name === 'filter1') {
          setFilter1(value);
        } else if (name === 'filter2') {
          setFilter2(value);
        }
      };
      const handleFormSubmit = (event) => {
        event.preventDefault();
    
        const filteredItems = schools.data.filter((item) => {
          console.log("item filter", item.school_section)
          console.log("item filter", item.medium)
          console.log("filter1",filter1)
          console.log("filter1",filter2)
          return (
            item.school_section === filter1 &&
            item.medium === filter2
          );
        });
    console.log("filteredItems",filteredItems)
        setFilteredData(filteredItems);
       
      };  
      const handleLinkClick = () => {
        setSearchParams({ param1: 'value1', param2: 'value2' });
      }; 
      console.log("All Schools",schools)   
        return(
            <>
           <section class="heroschools">
  <div class="heroschools-content">
    <h2 className="text-overlay" style={{color:'#ffffff'}}>संस्थेच्या सर्व शाळा खालील प्रमाणे</h2>
   
  </div> 
</section>


              <main id="main">
              <section id="clients" class="clients section-bg">
      <div class="container">

        <div class="row" data-aos="zoom-in">
        <form onSubmit={handleFormSubmit} className="mb-3">
        <div className="row g-3 align-items-center">
          <div className="col-auto">
            <label htmlFor="filter1" className="form-label">Section</label>
          </div>
          <div className="col-auto">
            <select className="form-select" name="filter1" id="filter1" value={filter1} onChange={handleFilterChange}>
              <option value="">All</option>
              <option value="pp">पूर्व प्राथमिक</option>
              <option value="p">प्राथमिक</option>
              <option value="s">माध्यमिक</option>
              <option value="hs">उच्च माध्यमिक</option>
            </select>
          </div>
          <div className="col-auto">
            <label htmlFor="filter2" className="form-label">Medium</label>
          </div>
          <div className="col-auto">
            <select className="form-select" name="filter2" id="filter2" value={filter2} onChange={handleFilterChange}>
              <option value="">All</option>
              <option value="marathi">मराठी</option>
              <option value="english">English</option>            
            </select>
          </div>
          <div className="col-auto">
            <button type="submit" className="btn btn-primary">Apply Filters</button>
          </div>
        </div>
      </form>

        <table className="table">
      <thead>
        <tr>
          <th style={{textAlign:'center'}}>Index</th>
          <th style={{textAlign:'center'}}>School Name</th>
          <th style={{textAlign:'center'}}>Section</th>
          <th style={{textAlign:'center'}}>Medium</th>
        </tr>
      </thead>
      <tbody>
        {filteredData.map((item,index) => (
          <tr>
            <td>{index+1}</td>
            <td>
           
            <Link to={{ pathname: '/schoolprofile', search: `?school=${item._id}` }} onClick={handleLinkClick}>
              {item.name}
              </Link>
              </td>
            <td>
              {
            item.school_section=='s'?'माध्यमिक':
            item.school_section=='hs'?'उच्च माध्यमिक':
            item.school_section=='pp'?'पूर्व प्राथमिक':
            item.school_section=='p'?'प्राथमिक':''

           }
          </td>
            <td>{
            item.medium=='marathi'?'मराठी':'English'
            }</td>
            
          </tr>
        ))}
      </tbody>
    </table>
        

        </div>

      </div>
    </section>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

       
        <div class="row">
         
        </div>

      </div>
    </section>

<footer id="footer">
<div class="footer-newsletter">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6" >
        <h4 style={{color:'white'}}>Find us On</h4>
        <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
        <i class="fa fa-youtube-play fa-3x "
       style={{paddingRight: "1em"}}
         aria-hidden="true"></i>
        <i class="fa fa-spotify fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
        <i class="fa fa-instagram fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
        <i class="fa fa-linkedin fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
        <i class="fa fa-facebook-square fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
      </div>
    </div>
  </div>
</div>
</footer>


</main>
            </>
        )
    }
  return (
  <WebTemplate>
    {SchoolsScreen()}
  </WebTemplate>
  )
}

export default Schools