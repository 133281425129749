import React,{useEffect,useRef,useState} from 'react'
import WebTemplate from '../Components/WebTemplate'
import { useGetAllSchoolsQuery} from '../Services/modules/school';
import { Link,useSearchParams  } from 'react-router-dom';

function ExamPriceCerimony() {
 
    const ExamPriceCerimonyScreen=()=>{  
    
        return(
            <>
           <section class="heroschoolsResult">
  <div class="heroschoolsResult-content">
    <h2 className="text-overlay" style={{color:'#ffffff'}}> बक्षिस समारंभ फोटो खालील प्रमाणे पहा</h2>
   
  </div> 
</section>


              <main id="main">
              <section id="clients" class="clients section-bg">
      <div class="container">

        <div class="row" data-aos="zoom-in">
    

        <table className="table">
      <thead>
        <tr>
        <th style={{textAlign:'center'}}>Index</th>
         
          <th style={{textAlign:'center'}}> बक्षिस समारंभ फोटो 2024</th>
         
        </tr>
      </thead>
      <tbody>
      <tr>
            <td>1</td>
            <td>           
          
             
             <a href='https://drive.google.com/drive/folders/1erGrS8Ci32mAhg7lcVud_D3hmjHjVMmR?usp=drive_link' target='_blank'>
            गुणवत्ता व शताब्दी शिष्यवृत्ती परीक्षा बक्षिस समारंभ कार्यक्रमाचे फोटो (2024) पाहण्यासाठी येथे क्लिक करा 
            </a>
             
              </td>           
          </tr>
        
      </tbody>
    </table>
        

        </div>

      </div>
    </section>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

       
        <div class="row">
         
        </div>

      </div>
    </section>



</main>
            </>
        )
    }
  return (
  <WebTemplate>
    {ExamPriceCerimonyScreen()}
  </WebTemplate>
  )
}

export default ExamPriceCerimony