import React,{useEffect,useRef,useState} from 'react'
import WebTemplate from '../Components/WebTemplate'
import { useGetAllSchoolsQuery} from '../Services/modules/school';
import { Link,useSearchParams  } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useGetAllCentersQuery, useGetResultMutation } from '../Services/modules/result';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PersonelResult from '../Components/PersonelResult';
function TseResult() {
 
    const TseResultScreen=()=>{  
    
        return(
            <>
             <ToastContainer />
           <section class="heroschoolsResult">
  <div class="heroschoolsResult-content">
    <h2 className="text-overlay" style={{color:'#ffffff'}}>गुणवत्ता शोध परीक्षा (2023) निकाल खालील प्रमाणे पहा</h2>
   
  </div> 
</section>


              <main id="main">
              <section id="clients" class="clients section-bg">
      <div class="container">

        <div class="row" data-aos="zoom-in">
        <Tab.Container defaultActiveKey="index1">
  <Nav variant="tabs">
    <Nav.Item>
      <Nav.Link eventKey="index1">
      वैयक्तिक निकाल
      </Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link eventKey="index2">
      केंद्रनिहाय गुणवत्ता यादी
      </Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link eventKey="index2">
      केंद्र-इयत्ता निहाय गुणवत्ता यादी
      </Nav.Link>
    </Nav.Item>
  </Nav>
  <div>
  <Tab.Content>
    <Tab.Pane eventKey="index1">
       
       <PersonelResult/>
   
    </Tab.Pane>
    <Tab.Pane eventKey="index2">
      <Link
        to={{ pathname: '/shatabdiResult' }}
        style={{ color: 'blue' }}
      >
        शताब्दि शिष्यवृत्ती परीक्षा 2023 (४थी व ७वी)
      </Link>
    </Tab.Pane>
  </Tab.Content>
  </div>

</Tab.Container>
        </div>

      </div>
    </section>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

       
        <div class="row">
         
        </div>

      </div>
    </section>

</main>
            </>
        )
    }
  return (
  <WebTemplate>
    {TseResultScreen()}
  </WebTemplate>
  )
}

export default TseResult