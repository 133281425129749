export default build =>
    build.query({
        tagTypes: ['allSchools'],
        keepUnusedDataFor: 30,
        query() {
            //console.log("payload",payload)            
            return {
                url: '/school/get',
                method: 'GET',
                //body: payload,
            };
           
        },
       providesTags: ['allSchools']
    })
