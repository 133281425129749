import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from '../Services/api'

const rootReducer  = combineReducers({  
    
    api: api.reducer,
  })
 
export const store = configureStore({
  reducer: rootReducer  ,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch);