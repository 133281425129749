import React,{useEffect,useRef,useState} from 'react'
import WebTemplate from '../Components/WebTemplate'
import { useGetAllSchoolsQuery, useGetSchoolByIdQuery} from '../Services/modules/school';
import { useSearchParams } from 'react-router-dom';
import backgroundImg from '../assets/img/cover50.jpg'

function SchoolProfile() {
    const [searchParams] = useSearchParams();
const [schoolDetails,setSchoolDetails]=useState([])
const[dataloading,setDataLoading]=useState(true)
const [imageUrl,setImageUrl]=useState(backgroundImg)
    const school_id = searchParams.get('school');
    const style = {
        background: `url(${imageUrl}) center center/cover no-repeat`,
      
      };
    const { 
        data: schools= [],  
        isLoading:loading=true,
        isSuccess:getSchoolSuccess=false
       } = useGetSchoolByIdQuery({school_id:school_id});
   useEffect(() => {    
     
    if (getSchoolSuccess) {
        setSchoolDetails(schools.data[0])
       console.log("SchoolData",schools)
       if(schools.data[0].school_photo_url)
       {
      
setImageUrl(`http://backend.sanglishikshansanstha.com/SchoolPhoto/${schools.data[0].school_photo_url}`)
      
       }
       
       setDataLoading(false)
         // setFilteredData(schools.data);
        
    }
   
}, [schools]);
    const SchoolProfileScreen=()=>{  
    
        return(
            <>
           <section className={`heroschoolsprofile`}           
           style={style}
          >
  <div class="heroschoolsprofile-content">
    <h2 className="text-overlay" style={{color:'#ffffff'}}>
        {
            !dataloading?
        schoolDetails.name:''
        }
        </h2>
   
  </div> 
</section>


              <main id="main">
              <section id="clients" class="clients section-bg">
      <div class="container">

        <div class="row" data-aos="zoom-in">
      
       
        </div>

      </div>
    </section>
    <section id="about" style={{backgroundColor:'rgb(249, 249, 249)'}} class="about">
  <div class="container" data-aos="fade-up">
  {
            !dataloading && schoolDetails.school_description && schoolDetails.school_description!=='undefined'?
           
                <>
                 <div class="section-title">
      <h2 style={{color:'black'}}>शाळेची माहिती</h2>
    </div>

    <div class="row content ">
        <div className='container'>
        <div class="col-lg-12">
       {schoolDetails.school_description}
       
      </div>
        </div>
    
      
    </div>
                </>
            
       :''
        }
   

  </div>
</section>

{/* <footer id="footer">
<div class="footer-newsletter">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6" >
        <h4 style={{color:'white'}}>Find us On</h4>
        <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
        <i class="fa fa-youtube-play fa-3x "
       style={{paddingRight: "1em"}}
         aria-hidden="true"></i>
        <i class="fa fa-spotify fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
        <i class="fa fa-instagram fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
        <i class="fa fa-linkedin fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
        <i class="fa fa-facebook-square fa-3x" 
         style={{paddingRight: "1em"}}
        aria-hidden="true"></i>
      </div>
    </div>
  </div>
</div>
</footer> */}


</main>
            </>
        )
    }
  return (
  <WebTemplate>
    {SchoolProfileScreen()}
  </WebTemplate>
  )
}

export default SchoolProfile